<template>
  <div id="app">
    <AppHeader  :tab_type=tab_type  :down_url=down_url   @updateTabType="handleUpdateTabType"  @updateDatalist="handleUpdateDatalist"></AppHeader>

    <ItemList :ad_contact=ad_contact v-bind:dataList="[adslist, danbaoList,tab_type]"></ItemList>

  </div>
</template>

<script>
import AppHeader from './components/appHeader.vue'
import ItemList from './components/itemList.vue'
import './assets/css/reset.css'
import './assets/css/index.css'
import axios from 'axios';

export default {
  name: 'App',
  data(){
    return {
      adslist:[],
      danbaoList:[],
      tab_type:0,
      originalWebsiteData: [], // 存储网址大全的原始数据
      originalGuaranteeData: [], // 存储担保平台的原始数据
      firsttime:new Date().getTime(),
      down_url:'',
      ad_contact: ''
    }
  },
  methods:{

    handleUpdateTabType(type) {
      //console.log(this.firsttime);
      this.tab_type = type;
      var nowtime = new Date().getTime();
      //console.log(nowtime);
      //console.log(type);
      if(type == 0){
        if(this.originalWebsiteData == '' || nowtime - this.firsttime >30000){
          axios.get('/api/search2', {params: {  category: this.tab_type }}).then(response => {
           this.originalWebsiteData = response.data; 
          }).catch(error => {
            // 请求失败时的处理逻辑
            console.error('Error fetching data:', error);
          });
          this.firsttime = nowtime;
        }
        this.adslist = this.originalWebsiteData;
      }else{
        if(this.originalGuaranteeData == '' || nowtime - this.firsttime >30000){
          axios.get('/api/search2', {params: {  category: this.tab_type }}).then(response => {
           this.originalGuaranteeData = response.data; 
          }).catch(error => {
            // 请求失败时的处理逻辑
            console.error('Error fetching data:', error);
          });
          this.firsttime = nowtime;
        }
        this.danbaoList = this.originalGuaranteeData;
      }

    },
    handleUpdateDatalist(keyword){

      axios.get('/api/search2', {
        params: { keyword: keyword, category: this.tab_type }
      })
      .then(response => {
        // 请求成功的回调函数
        if (this.tab_type === 0) {
          this.adslist = response.data;
          if (keyword === '') {
            this.originalWebsiteData = response.data;
          }
        } else {
          this.danbaoList = response.data;
          if (keyword === '') {
            this.originalGuaranteeData = response.data;
          }
        }
      })
      .catch(error => {
        // 请求失败的回调函数
        console.error('Error fetching data:', error);
      });
    },
    compareVersions(version1, version2) {
        // 将版本号字符串按点分隔，转换为数组
        const v1 = version1.split('.').map(Number);
        const v2 = version2.split('.').map(Number);

        // 逐个比较版本号的每一部分
        for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
            const num1 = v1[i] || 0; // 如果部分缺失，则补 0
            const num2 = v2[i] || 0;

            if (num1 > num2) {
                return 1; // version1 大于 version2
            } else if (num1 < num2) {
                return -1; // version1 小于 version2
            }
        }

        return 0; // version1 等于 version2
    },
    async checkForUpdates() {
        try {
            const response = await fetch('/api/check-version');
            const latestVersionInfo = await response.json();
            const currentVersion = '1.0.1'; // 当前应用版本号
            const version2 = latestVersionInfo.latest_version;
            if (this.compareVersions(currentVersion, version2) == -1) {
                console.log(`${currentVersion} 小于 ${version2}`);
                this.promptUserToUpdate(latestVersionInfo);
            }
 
        } catch (error) {
            console.error('Error checking for updates:', error);
        }
    },
    promptUserToUpdate(versionInfo) {
        // 提示用户下载最新版本，或者自动下载并安装
        // 可以弹出一个对话框，让用户确认下载新版本
        if (confirm(`发现新版本 ${versionInfo.latest_version}，是否更新？`)) {
            this.downloadAndUpdate(versionInfo.download_url);
        }
    },
    downloadAndUpdate(downloadUrl) {
        window.location.href = downloadUrl;
    },
    
  },
  components: {
    AppHeader,
    ItemList
  },
  created(){
      axios.get('/api/ads2')
      .then(response => {
        // 请求成功时的处理逻辑
        this.adslist = response.data[0];
        this.originalWebsiteData = response.data[0];

        this.danbaoList = response.data[1];
        this.originalGuaranteeData = response.data[1];
        //console.log(response.data[2]['download_url']);
        this.down_url = response.data[2]['download_url'];
        this.ad_contact = response.data[2]['ad_telephone'];
        //版本检测,网页版需要注释掉
        //this.checkForUpdates();
      })
      .catch(error => {
        // 请求失败时的处理逻辑
        console.error('Error fetching data:', error);
      });
  },
  mounted(){

  },
  computed: {

  },
}
</script>

<style>
.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.progress {
  height: 20px;
  background-color: #4caf50;
  width: 0%;
  border-radius: 5px;
}
</style>
