<template>
  <div class="header">

    <div class="top">
        <div class="first" v-if="isLogoVisible">
          <div class="logo">49寻宝</div> 
           <div class="sousuo">
           <div @click="toggleInput" >
            <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="7" stroke="white" stroke-width="2"/>
              <line x1="15" y1="15" x2="20" y2="20" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
            </div>
          </div>
          <div class="xiazai"><a href="#" @click="handleClick" target="_blank"><img src="../assets/Frame696.png" /></a></div>
        </div>
        <div class="first_search" v-if="isInputVisible">
          <div class="search_input">
              <img src="../assets/search.png" />
              <input type="text"  v-model="searchInput" ref="seaInput" placeholder="请输入您想要的内容"/>
          </div>
          <div class="search_btn" @click="showLogo">取消</div>
        </div>
        <div class="second" style="user-select: none;">
           
            <div class="one"><span>每天访问量</span></div><div class="one_middle"></div><div class="two">超一千万IP</div>

        </div>
        <div class="third">
          <div class="nav">
            <span :class="{ 'active': tab_type === 0 }" @click="updateTabType(0)" style="user-select: none;">网址大全</span><div style="display:inline-block;font-size:16px;width:3%;"></div>
            <span :class="{ 'active': tab_type === 1 }" @click="updateTabType(1)" style="user-select: none;">担保平台</span>
          </div>
        </div>
        
    </div>
    

  </div>
</template>

<script>
  export default {
    name: 'AppHeader',
    props: {
      tab_type: null,
      down_url:String,
    },
    data() {
      return {
        searchInput: '', // 添加一个数据属性用于绑定输入框的值
        isInputVisible: false,
        isLogoVisible: true
      }
    },
    components: {
     
    },
    computed: {
     
    },
    methods: {
      updateTabType(type) {
        this.searchInput='';
        this.$emit('updateTabType', type);
      },
      updateDatalist(){
        this.$emit('updateDatalist', this.searchInput);
      },
      handleClick() {
        
        const url = this.down_url;
        window.open(url, '_blank');
      },
      toggleInput(){
        this.isInputVisible = true;
        this.isLogoVisible = false;
        this.$nextTick(() => {
          if (this.$refs.seaInput) {
            this.$refs.seaInput.focus();
          }
        });
        
      },
      showLogo(){
        this.isInputVisible = false;
        this.isLogoVisible = true;
      }
    },
    mounted() {

    },
    watch: {
      searchInput(newVal) {
        
        this.$emit('updateDatalist', newVal);
      }
  }

  };
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
