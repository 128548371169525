import { createRouter, createWebHistory } from 'vue-router';
import App from '../App.vue'; // 请根据实际的文件路径修改这里

const routes = [
  { path: '/', component: App },
  // 其他路由配置
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
